/*
 * mm-basic
 *
 * Here we overide the mmenu module mm-basic theme css
 *
 * [1] Correct the off canvas slide behaviour
 */

$color-link-active: #ee2f59;
$color-link: #1a488c;
$color-background: #f8f9fa;
$color-border: #cccccc;

/* [1] */
html.mm-slide.mm-right.mm-opened .mm-menu {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

html.mm-slide.mm-right.mm-opening .mm-menu {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

html.mm-top.mm-opening .mm-slideout {
  -webkit-transform: translate(0, 81px);
  -moz-transform: translate(0, 81px);
  -ms-transform: translate(0, 81px);
  -o-transform: translate(0, 81px);
  transform: translate(0, 81px);
}

html.mm-slide.mm-top.mm-opened .mm-menu {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

html.mm-slide.mm-top.mm-opening .mm-menu {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

html.mm-opened .mm-page {
  box-shadow: none;
}

.mm-menu {
  z-index: 2;
  background-color: $color-background;
  > .mm-list {
    box-shadow: inset 10px 0 10px -10px #000000;
  }
  .mm-list {
    padding: 0;
    width: 100%;
    margin: 0;
    &.mm-panel {
      //padding: 11px 20px;
    }
    > li {
      &:after {
        border-color: transparent;
        border-bottom-width: 0!important;
      }
      > a {
        &.mm-subopen:before {
          border-color: $color-border;
        }
        &.mm-subopen:after {
          border-color: $color-link;
        }
        &.mm-subclose {
          background-color: $color-background;
          color: $color-link-active;
        }
        &.mm-subclose:before {
          border-color: $color-border;
        }
      }
    }
  }
}

.mm-list {
  > li {
    > span {
      padding: 0;
    }
    > a {
      font-size: 18px;
      padding: 16px;
      border-bottom: 1px solid $color-border;
      &:focus {
        outline-offset: 0;
        outline: none;
      }
      &:hover,
      &.active {
        background-color: transparent;
      }
      &.mm-subopen:hover { background: $color-link-active; }
    }
  }
  a.mm-subclose:before {
    margin-bottom: -11px;
  }
  &.nav {
    > li {
      > a {
        color: $color-link;
        &.active-trail,
        &:focus, &:hover {
          color: $color-link-active;
        }
      }
    }
  }
}
